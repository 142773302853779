import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { setupVueErrorHandling, setupPageTracking } from './appinsights-vue'
import Vue from 'vue'

export default function (ctx, inject) {
  const config = {"instrumentationKey":"c58f6394-940f-4dbf-82d6-ccb1cd5a8df4"}
  // runtimeConfig
  const runtimeConfig = ctx.$config && ctx.$config.appInsights || {}

  const appInsights = new ApplicationInsights({
     config: {...config, ...runtimeConfig}
  })

  inject('appInsights', appInsights)

  // Initialize appInsights

  // Initialize appInsights

  appInsights.loadAppInsights()
  setupVueErrorHandling(Vue, appInsights)

  setupPageTracking(ctx.app.router, appInsights)
}
